const frenchTranslation = {
  translation: {
    // Navigation
    "Your gift": "Votre don",
    "Donor info": "Infos du donateur",
    Payment: "Paiement",
    "Beat as one": "Combattons, ensemble.",
    "Help Rally":
      "Aidez les meilleurs chercheurs à réaliser des avancées décisives en santé cardiaque et cérébrale. Combattons les maladies du cœur et l’AVC !",
    "Give now": "SVP donnez maintenant.",
    Address:
      "Fondation des maladies du cœur et de l’AVC du Canada\n2300, rue Yonge, bureau 1200, Case postale 2414\nToronto (Ontario) M4P 1E4\n1-888-473-4636\ndons@coeuretavc.ca\nLundi au vendredi : 9 h à 20 h\nSamedi : 9 h à 17 h",
    "Privacy policy": "Politique de confidentialité",
    "Terms of use": "Conditions d’utilisation",

    // Donation Type
    "What type of gift": "Quel type de don aimeriez-vous faire?",
    "Choose gift amount": "Informations sur le don",
    "one-time": "Unique",
    monthly: "Mensuel",
    "in-honour": "En l'honneur de",
    "in-memory": "À la mémoire de",
    "Honour Gift Type": "Type de don",
    "Honouree Name": "Nom de la personne honorée",
    "Tribute Card Type": "Type de carte",
    "No Card": "Aucune carte",
    "Send an eCard": "Envoyer une carte électronique.",
    "Mail a card": "Envoyer une carte par la poste en mon nom.",
    "Your personal message": "Votre message personnel",
    "Card will be blank": " (la carte sera vierge si elle n'est pas remplie)",
    "Duplicate ecard checkbox":
      "Oui, envoyez-moi une copie de la carte électronique.",
    Other: "Autre",
    "Next Step": "Étape suivante",
    "In Honour Gift Checkbox":
      "Oui, il s’agit d’un don effectué en l'honneur ou à la mémoire d'une personne.",
    "Recipient's Email": "Adresse courriel du destinataire",
    "eCard Subject": "Objet de la carte électronique",
    "Full Name": "Prénom et nom",
    "Card recipient info": "Coordonnées du destinataire",
    "Select card": "Veuillez choisir une carte électronique.",
    "Footer Text":
      "Fondation des maladies du cœur et de l’AVC du Canada N° d’enregistrement d’organisme de bienfaisance : 10684-6942-RR0001\n\nFondation des maladies du cœur et de l’AVC du Nouveau-Brunswick N° d’enregistrement d’organisme de bienfaisance : 11924-6940-RR0001\n\n<sup>MC</sup> L’icône du cœur et de la / seule et l’icône du cœur et de la / suivie d’une autre icône ou de mots en français ou en anglais sont des marques de commerce de la Fondation des maladies du cœur et de l’AVC du Canada.\n\n© {{year}} Fondation des maladies du cœur et de l’AVC du Canada. Tous droits réservés.",
    "Honouree Street Address": "Rue",
    "Honouree City": "Ville",
    "Honouree State / Province": "Province / État",
    "Honouree Postal": "Code postal / code ZIP",
    "Honouree Country": "Pays",
    "Default in-memory ecard message":
      "Mes plus sincères condoléances dans cette période difficile.",
    "Default tribute subject": "Un don a été fait en hommage à",

    // Donor info
    "Provide your info": "Informations du donateur",
    "Required info": "Renseignements nécessaires",
    "Company gift": "Enterprise",
    "Company Name": "Enterprise",
    Title: "Titre",
    "First Name": "Prénom pour la facturation",
    "Last Name": "Nom de famille pour la facturation",
    "Street Address": "Rue de facturation 1",
    City: "Ville de facturation",
    "State / Province": "Province / État où a lieu la facturation",
    Postal: "Code postal / code ZIP pour la facturation",
    Country: "Pays de facturation",
    "Address 2": "Adresse 2",
    "Phone Number": "Numéro de téléphone",
    "Email Address": "Adresse de courriel",
    "Send Monthly Tips":
      "Oui! Je veux des conseils santé, des nouvelles sur la recherche, et plus.",
    Back: "Retour",

    // Thank you page
    "thank you for gift": "merci pour votre généreux don",
    "Together, we'll": "",
    beat: "Combattons ensemble",
    "heart disease and stroke": "les maladies du cœur et l’AVC.",
    "thank you for supporting breakthrough":
      "Nous vous remercions de propulser les découvertes médicales qui contribuent à faire avancer la prévention, le diagnostic et le traitement des maladies du cœur et d’AVC.",
    "tax receipt":
      "Vous recevrez bientôt votre reçu fiscal par courriel. Si vous avez des questions ou des préoccupations, envoyez-nous un message à",
    "See the impact": "Découvrez l’impact de vos dons",
    "Start a new donation": "Commencer une nouvelle donation",
    "Credit Card": "Carte de crédit",
    "Bank Withdrawal": "Prélèvement automatique",
    "Your confirmation number": "Votre numéro de confirmation est:",
    "TY researcher name":
      "La Dre {{researcherName}}, chercheuse pour Cœur + AVC",

    // Error messages
    "Select gift type": "Veuillez choisir le type de don",
    "Select gift amount": "Veuillez sélectionner le montant du cadeau",
    "Enter correct amount": "Veuillez entrer le montant correct",
    "Enter honouree name": "Veuillez entrer le nom de la personne honorée",
    "Select honour gift type": "Veuillez choisir le type de don",
    "Enter company name": "Veuillez entrer le nom de l’entreprise",
    "Enter first name": "Veuillez entrer le prénom",
    "Enter last name": "Veuillez entrer le nom de famille",
    "Enter street address": "Veuillez entrer l’adresse (numéro et rue)",
    "Enter postal": "Veuillez entrer le code postal",
    "Enter city": "Veuillez entrer la ville",
    "Select State / Province": "Veuillez choisir la province/le territoire",
    "Select State / Province Input":
      "Veuillez entrer la province/le territoire",
    "Select country": "Veuillez sélectionner pays",
    "Enter email": "Veuillez indiquer l’adresse courriel",
    "Provide valid email": "Veuillez fournir une adresse courriel valide",
    "Provide valid email or select print":
      "Veuillez fournir un e-mail valide ou sélectionner imprimer le reçu",
    "Enter recipient email":
      "Veuillez entrer l’adresse courriel du destinataire",
    "Enter full name": "Veuillez entrer le nom complet",
    "Provide ecard subject":
      "Veuillez indiquer l’objet de la carte électronique",
    "Provide personal message": "Veuillez fournir un message personnel",
    "More than 5": "Le montant minimum pour un don est de 5$",
    "First name greater than 2": "Le prénom doit compter plus de 2 caractères",
    "First name less than 50":
      "La longueur du prénom doit être inférieure à 50",
    "Last name greater than 2":
      "Le nom de famille doit compter plus de 2 caractères",
    "Last name less than 100": "La longueur du nom doit être inférieure à 100",
    "Loyalty card equal 9": "Veuillez fournir un numéro à 9 chiffres",
    "Invalid phone number": "Veuillez entrer le numéro de téléphone valide",
    "Enter Call Centre Call Number":
      "Veuillez entrer le numéro d'appel du centre d'appels",
    "Institution number 3 digits":
      "Le numéro d'établissement doit être composé de 3 chiffres",
    "Transit number 5 digits":
      "Numéro de domiciliation doit comporter 5 chiffres",
    "Account number 5 digits":
      "Le numéro de compte doit comporter au moins 5 chiffres",
    "Verified account number 5 digits":
      "Le numéro de compte vérifié doit comporter au moins 5 chiffres",
    "Account numbers do not match":
      "Les numéros de compte ne correspondent pas",

    // Payment
    "Complete payment info": "Remplir les informations sur le paiement",
    "Complete Donation": "Effectuer le don",
    "You will be making a": "Vous verserez un don",
    "donation of": "de",
    "Agree to charge credit card":
      "En cochant cette option, j'accepte d'utiliser ma carte de crédit comme mode de paiement et j'autorise cette organisation à débiter mon compte de carte de crédit pour remplir mon engagement de don.",
    "Agree to cover fee": "Je veux couvrir les frais de mon don (2,00 $).",
    "Did you know you can cover fees":
      "Saviez-vous qu'en aidant à compenser les frais de traitement, nous pourrons faire plus avec votre don?",
    Loading: "Chargement en cours...",
    "Your monthly gift process":
      "Vos futurs dons mensuels seront traités le {{date}} de chaque mois, à partir du {{fullDate}}.",
    "One time terms":
      "En complétant cette étape, vous autorisez la Fondation des maladies du cœur du Canada à utiliser les informations de votre carte pour effectuer votre don.",
    "Monthly terms card":
      "En cochant cette option, j'accepte d'utiliser ma carte de crédit comme mode de paiement et j'autorise cette organisation à débiter mon compte de carte de crédit pour remplir mon engagement de don.",
    "Monthly terms debit":
      "En cochant cette option, j'accepte d'utiliser mon compte de banque comme méthode de paiement et autorise cette organisation à débiter mon compte de banque pour remplir mon engagement au don.",
    "starting today": ", à partir d'aujourd'hui.",

    // Debit Form
    "Institution number": "Numéro d'établissement (3 caractères numériques)",
    "Transit number": "Numéro de domiciliation (5 caractères numériques)",
    "Account number": "Numéro de compte de banque (5-12 caractères numériques)",
    "Verify account number":
      "Vérifier le numéro de compte de banque (5-12 caractères numériques)",

    // Provinces
    Alberta: "Alberta",
    "British Columbia": "Colombie-Britannique",
    Manitoba: "Manitoba",
    "New Brunswick": "Nouveau-Brunswick",
    Newfoundland: "Terre-Neuve et Labrador",
    "Nova Scotia": "Nouvelle-Écosse",
    Northwest: "Territoires du Nord-Ouest",
    Nunavut: "Nunavut",
    Ontario: "Ontario",
    "Prince Edward": "Île-du-Prince-Édouard",
    Quebec: "Québec",
    Saskatchewan: "Saskatchewan",
    Yukon: "Yukon",

    // Aeroplan
    "Are you aeroplan": "Êtes-vous membre du programme Aéroplan?",
    "Get points":
      "Obtenez 4 points Aéroplan pour chaque don de 1 $ à la Fondation des maladies du cœur et de l'AVC*.",
    "9-digit number": "(Numéro à 9 chiffres)",
    "Aeroplan Footer":
      "\nOffre valable jusqu\u0027au 31 janvier 2023 : obtenez 4 points Aéroplan pour chaque dollar donné à la Fondation des maladies du c\u0153ur et de l\u0027AVC sur cette page de collecte. Limite de 2 500 points en prime par transaction, conformément à la réglementation de l\u0027ARC. Veuillez prévoir de 6 à 8 semaines pour l\u0027ajout des points dans votre compte.",

    // Popup
    "Something went wrong": "Quelque chose s'est mal passé. Veuillez réessayer",
    "Please correct your donation information":
      "Veuillez corriger les renseignements sur votre don, comme indiqué dans le formulaire, puis essayer de nouveau.",
    "Welcome back":
      "Rebienvenue{{firstName}}. Merci de votre soutien. Nous avons prérempli quelques champs du formulaire pour vous.",
    "Incorrect card":
      "Veuillez fournir des détails de carte de crédit corrects.",
    "Technical difficulties":
      "Nous rencontrons quelques difficultés techniques. Veuillez réessayer plus tard.",
    "Agree to terms": "Veuillez accepter les conditions afin de continuer",
    "Process request":
      "Veuillez patienter pendant que nous traitons votre demande",
    "Session timeout": "Votre session a expiré. Veuillez recharger la page",
    "Session time left": "Votre session se termine dans {{sessionTimeLeft}}",
    "Stay connected": "Rester connecté",
    Reload: "Recharger",
    "Can't process request":
      "Désolé, nous ne pouvons pas traiter la demande pour le moment. Veuillez réessayer plus tard",

    // Call Center
    "Recording on": "Activer l'enregistrement",
    "Recording off": "Désactiver l'enregistrement",
    "Receipts blurb":
      "Nous proposons des reçus par e-mail car il s'agit du mode de livraison le plus rapide et le plus efficace. Veuillez fournir votre adresse e-mail afin que nous puissions vous envoyer votre reçu.",
    "Print receipt requested": "Impression du reçu demandé",
    "Contact Centre Call Number": "Numéro d'appel du centre de contact",
    "Tribute Report": "Demande de rapport d'hommage",
    "Valid email address":
      "Veuillez saisir une adresse e-mail valide afin de recevoir votre reçu fiscal officiel.",

    // Error page
    "We have run into issues":
      "Désolé, nous avons rencontré quelques problèmes.",
    "Call center agents":
      "Vous pouvez également contacter nos agents du centre d'appels, ils vous aideront à traiter votre don par téléphone. Appelez-nous au ",

    // Titles
    Mr: "M.",
    Ms: "Mme",
    Mrs: "Mme",
    Miss: "Mlle",
    Dr: "Dr",

    // Booleans
    Yes: "Oui",
    No: "Non",
  },
};

export default frenchTranslation;
