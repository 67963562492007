import {
  InformationalBlurbContainer,
  LeftBlockContainer,
} from "pages/donation/donation-page.styles";
import { FC } from "react";
import FormSkeleton from "components/skeletons/form-skeleton/form-skeleton.component";
import SkeletonBlock from "components/skeleton-block/skeleton-block.component";
import { FooterContainer } from "components/footer/footer.styles";

interface Props {
  isContextual: boolean;
}

const LeftPartSkeleton: FC<Props> = ({ isContextual }) => {
  return (
    <LeftBlockContainer isContextual={isContextual}>
      {!isContextual && (
        <InformationalBlurbContainer>
          <SkeletonBlock
            height="150px"
            width="80%"
            styles={{ margin: "0 auto" }}
          />
        </InformationalBlurbContainer>
      )}
      <FormSkeleton isContextual={isContextual} />

      <FooterContainer>
        <SkeletonBlock height="137px" backgroundColor="#D4D4D4" />
      </FooterContainer>
    </LeftBlockContainer>
  );
};

export default LeftPartSkeleton;
