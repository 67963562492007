import styled from "styled-components";

export const NavButtonSkeletonContainer = styled.div`
  width: 33.33333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  gap: 6px;

  &:first-of-type {
    border-bottom: 2px solid #f0f0f0;
  }
`;

export const FormSkeletonContainer = styled.div<{ isContextual: boolean }>`
  ${(props) => !props.isContextual && "padding: 0 20%;"}

  @media only screen and (max-width: 930px) {
    ${(props) => !props.isContextual && "padding: 0 10%;"}
  }

  @media only screen and (max-width: 690px) {
    ${(props) => !props.isContextual && "padding: 0"}
  }
`;

export const HeadingWithButtonsContainer = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
`;

export const TypeButtonsSkeletonContainer = styled.div`
  gap: 45px;
  display: flex;

  @media only screen and (max-width: 500px) {
    gap: 20px;
  }

  @media only screen and (max-width: 320px) {
    gap: 10px;
  }
`;

export const AmountButtonSkeletonContainer = styled.div`
  width: 65px;
  height: 65px;

  @media only screen and (max-width: 1306px) {
    width: 30%;
  }
`;

export const OtherInputSkeletonContainer = styled.div`
  flex: 1;
  display: flex;
  min-width: 300px;
  height: 65px;
`;

export const OffsetFeesSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  margin-bottom: 20px;

  & > div:first-child {
    @media only screen and (max-width: 1840px) {
      height: 42px;
    }
  }
`;

export const ButtonSkeletonContainer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: end;
`;
