import SkeletonBlock from "components/skeleton-block/skeleton-block.component";
import { RightBlockContainer } from "pages/donation/donation-page.styles";
import { FC } from "react";

const ContImageSkeleton: FC = () => {
  return (
    <RightBlockContainer isContextual={true}>
      <SkeletonBlock />
      <SkeletonBlock
        height="185px"
        width="94%"
        backgroundColor="#D4D4D4"
        styles={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        withAnimation={false}
      />
    </RightBlockContainer>
  );
};

export default ContImageSkeleton;
